import { select, call, put } from 'redux-saga/effects';
import { getMsrpPricingFromAutobahn } from '../../api/eleanorApi';
import {
  configSelector,
  productDetailsSelector,
  configuratorSelector,
} from '../../selectors';
import {
  getSelectedChoices,
  getPriceData,
} from './_utils';
import isPreviewProduct from '../../utils/isPreviewProduct';
import { getMsrpPricingSuccess } from '../../actions/pricingActions';
import { GetMSRPPricingError } from '../../customErrors/GetMSRPPricingError';

const getOptionType = (parentOptionType, tags) => {
  if (tags.includes('custom-size-width')) {
    return 'Width';
  } if (tags.includes('custom-size-height')) {
    return 'Height';
  }

  return parentOptionType;
};
function* getMsrpPricing(args) {
  const { options, choices } = args;
  const { productInfo } = yield select(configuratorSelector);
  const { productId } = productInfo;
  const { siteId, partnerProductId } = yield select(configSelector);
  const { productLineId } = yield select(productDetailsSelector);
  const selectedChoices = getSelectedChoices(options, choices);
  const selections = selectedChoices
    .map((x) => ({
      optionId: x.optionId,
      choiceId: x.id,
      choiceValue: x.value,
      tags: x.tags,
      optionType: getOptionType(x.parentOptionType, x.tags),
    }));

  const data = {
    siteId,
    productId,
    partnerProductId,
    productLineId,
    choices: selections,
  };

  try {
    const pricing = yield call(getMsrpPricingFromAutobahn, data);
    yield put(getMsrpPricingSuccess());
    return getPriceData(pricing.originalPrice, pricing.realPrice);
  } catch (error) {
    if (isPreviewProduct) {
      const selected = selectedChoices.map((choice) => {
        const option = options[choice.optionId];
        return {
          choiceValue: choice.dataType === 'List' ? choice.internalName : choice.value,
          choiceName: choice.internalName,
          optionName: option.internalName,
        };
      });
      throw new GetMSRPPricingError(error, selected);
    }
    throw error;
  }
}

export default getMsrpPricing;
